<template>
  <div id="event-calendar">
    <div>
      <b-navbar
        class="other"
        variant="faded"
        type="light"
        toggleable="lg"
        fixed="top"
      >
        <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
        <b-navbar-brand :to="'/'">
          <img :src="Group49" alt="Mongolia" class="mr-4" />
          <span class="text-brand">EVENT CALENDAR</span>
        </b-navbar-brand>
      </b-navbar>

      <div class="contentig">
        <div class="ig" ref="ig">
          <ImagesBoard :grid="true" :rows="4" :columns="2" />
        </div>
      </div>
    </div>

    <div class="box-right">
      <div class="img-header"></div>
      <div class="featured-events">
        <span>Featured Events</span>
        <div class="list-featured-events">
          <div class="box" v-for="event in events" :key="event.id">
            <div class="box-detail">
              <div
                class="img-featured-events"
                :style="{
                  backgroundImage: event.thumbnailUrl
                    ? 'url(' + event.thumbnailUrl + ')'
                    : null,
                }"
              >
                <div class="text-center">
                  <span class="month">{{
                    getMonthEvent(
                      event.eventTime.startDate,
                      event.eventTime.endDate
                    )
                  }}</span>
                  <br />
                  <span class="days">{{
                    getDayEvent(
                      event.eventTime.startDate,
                      event.eventTime.endDate
                    )
                  }}</span>
                </div>
                <div class="event">Event</div>
              </div>
              <div class="title" v-bind:title="event.title">
                {{ event.title | truncate(23) }}
              </div>
              <div class="description">
                {{ event.description | truncate(80) }}
                <span class="more">More</span>
              </div>
              <button
                type="button"
                class="btn btn-explore btn-block"
                @click="() => onClickEvent(event.id)"
              >
                Explore
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="event-all">
        <div class="filter-event">
          <div class="select">
            <select value class="form-control">
              <option
                v-for="(item, index) in filters.year"
                :key="index"
                :value="item"
                >{{ item }}</option
              >
            </select>
          </div>
          <div class="select">
            <select value class="form-control">
              <option value>See all events</option>
              <option
                v-for="(item, index) in filters.month"
                :key="index"
                :value="item"
                >{{ item }}</option
              >
            </select>
          </div>
          <div class="select">
            <select value class="form-control">
              <option value>All Regions</option>
              <option
                v-for="(item, index) in filters.locations"
                :key="index"
                :value="item.id"
                >{{ item.title }}</option
              >
            </select>
          </div>
        </div>
        <div class="post-event">
          <ContentsBoard
            :locations="[]"
            :types="['event']"
            leftfilter="false"
          />
        </div>
      </div>
    </div>
    <NavrightHome />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment";
import EventPopupManager from "@/services/EventPopupManager";
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";
import Group49 from "@/assets/img/offers/Group 49.svg";
import ContentsBoard from "@/components/ContentsBoard";
import { log } from "util";

export default {
  name: "event-calendar",
  components: {
    NavrightHome,
    ImagesBoard,
    ContentsBoard,
    // EventPopup
  },
  data() {
    const locations = this.$store.state.ref.locations;
    const year = [2019, 2020, 2021, 2022, 2023];
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return {
      Group49,
      showPopup: true,
      events: [],
      final: true,
      page: 1,
      perPage: 30,
      filters: {
        year,
        month,
        locations,
      },
      selectedFilter: {
        year: [],
        month: [],
        locations: [],
      },
    };
  },
  methods: {
    reset() {
      this.final = true;
      this.events = [];
      this.page = 1;
    },
    load() {
      const url = process.env.VUE_APP_API_URI;
      const postData = {
        query: `query events($page: Int, $limit: Int) { events(page: $page, limit: $limit) { final data { id title name thumbnailUrl description eventTime{ startDate endDate } } } }`,
        variables: {
          page: this.page,
          limit: this.perPage,
        },
      };
      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.events = res.data.data.events.data;
          this.final = res.data.data.events.final;
        })
        .catch(console.error);
    },
    onClickEvent(id) {
      EventPopupManager.open(id);
    },
    loadNext() {
      this.page++;
      this.load();
    },
    getMonthEvent(startDate, endDate) {
      return moment.utc(startDate).format("MMM");
    },
    getDayEvent(startDate, endDate) {
      const monthStart = moment.utc(startDate).format("MMM");
      const monthEnd = moment.utc(endDate).format("MMM");
      if (!moment.utc(startDate).isSame(endDate, "month")) {
        return moment.utc(startDate).format("D");
      } else {
        if (moment.utc(startDate).isSame(endDate, "day")) {
          return moment.utc(startDate).format("D");
        } else {
          return (
            moment.utc(startDate).format("D") +
            " - " +
            moment.utc(endDate).format("D")
          );
        }
      }
    },
    onClose() {
      this.$emit("close");
    },
  },
  mounted() {
    this.load();
    // this.showPopup = true;
  },
};
</script>

<style lang="scss" scoped>
#event-calendar {
  .other {
    position: fixed;
    align-items: center;
    width: 24.75%;
    background-color: white;
    height: 15.5vh;
    .text-brand {
      margin-top: 20px;
      color: red;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .mc {
    margin: auto;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      .nav-link {
        color: #e13036;
        padding-top: 0px;
        &:hover {
          color: red;
        }

        &.router-link-exact-active {
          span {
            color: #e13036;
            padding-bottom: 5px;
            border-bottom: 3px solid #e13036;
          }
        }
      }
    }
  }

  .contentig {
    width: 27.3%;
    .ig {
      z-index: 10;
      position: fixed;
      width: 24.75%;
      height: calc(100vh - 102px);
      box-sizing: content-box;
      padding-top: 102px;
      background-color: #999;
      overflow: hidden;
    }
  }

  .box-right {
    position: absolute;
    right: 0;
    padding-bottom: 100%;
    min-height: 100vh;
    width: 75.25%;

    .img-header {
      width: 100%;
      min-height: 45.74vh;
      background-image: url("../assets/img/bg-event-calendar.jpg");
      background-size: cover;
    }

    .featured-events {
      padding: 25px 5.6% 0 3%;
      span {
        font-size: 30px;
        font-weight: bold;
      }

      .list-featured-events {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;

        .box {
          width: 25%;
          margin-bottom: 15px;
          padding: 0 8px;

          .box-detail {
            border: #b9b9b9 1px solid;
            padding: 5px;
            .img-featured-events {
              height: 135px;
              background-image: url("../assets/img/img-event.jpg");
              background-size: cover;
              background-position: 100%;
              position: relative;
              color: #fff;

              .month {
                font-size: 20px;
                font-weight: bold;
              }

              .days {
                font-size: 59px;
                font-weight: bold;
              }

              .event {
                padding: 0 30px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                color: #fff;
                background-color: #e13036;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
              }
            }

            .title {
              padding: 2%;
              font-size: 17px;
              font-weight: bold;
            }

            .description {
              padding: 2%;
              font-size: 12px;
            }

            .more {
              font-size: 12px;
            }
            .btn-explore {
              color: #e13036;
              border: #e13036 1px solid;
              border-radius: 0px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .showPopup {
      display: block;

      &.showPopup {
        display: none;
      }
    }

    .event-all {
      padding: 25px 5.6% 0 3%;
      .filter-event {
        display: flex;
        margin-left: -14px;
        margin-right: -14px;
        .select {
          width: 33.33%;
          padding: 0 14px;
          position: relative;

          .form-control {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: #e13036;
            border: #b9b9b9 1px solid;
            option {
              color: #2c2c2c;
            }
          }

          &:after {
            content: url("../assets/img/arrow-down.png");
            position: absolute;
            right: 20px;
            top: 55%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1920px - 1360px) {
  .other {
    width: 27.3%;
  }
  .contentig {
    width: 20%;
  }
}
</style>
